import {Container, Row, Col, Card} from 'react-bootstrap';


function InitiativesSection() {
    return (
        <Container className="initiatives-section" id="initiatives">
            <Row>
                <Col xs={12}>
                <h2>Initiatives</h2>
                </Col>  
            </Row>
            <Row className="initiatives-section-row-2">
                {/* <Col md={4}>
                    <Card className="initiatives-section-card h-100">
                        <div class="middle">
                            <a href="http://rm.dataforchange.in:3000/d/LNpFLX77k/wayanad-rainfall-monitoring-system?orgId=1" target="_blank">
                                <div class="text">
                                        View Details
                                </div>
                            </a>
                        </div>
                        <span class="card-content">
                            <img className="initiatives-section-card-img" src="/images/icon-1.png" alt="" />
                            <Card.Body>
                                <Card.Title>Rain Monitoring - Wayanad</Card.Title>
                                <Card.Text>
                                    A citizen science initiative for rain forecasting.
                                </Card.Text>
                            </Card.Body>
                        </span>
                    </Card>
                </Col> */}
                <Col md={4}>
                    <Card className="initiatives-section-card h-100">
                        <div class="middle">
                            <a href="https://lab.gpsrenewables.com" target="_blank">
                                <div class="text">
                                        View Details
                                </div>
                            </a>
                        </div>
                        <span class="card-content">
                            <img className="initiatives-section-card-img" src="/images/icon-2.png" alt="" />
                            <Card.Body>
                                <Card.Title>Landslide Susceptibility Map</Card.Title>
                                <Card.Text>
                                    Open Data and Machine Learning for better landslide prediction in Kerala.
                                </Card.Text>
                            </Card.Body>
                        </span>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className="initiatives-section-card h-100">
                        <div class="middle">
                            <a href="https://dm.dataforchange.in" target="_blank">
                                <div class="text">
                                        View Details
                                </div>
                            </a>
                        </div>
                        <span class="card-content">
                            <img className="initiatives-section-card-img" src="/images/icon-3.png" alt="" />
                            <Card.Body>
                                <Card.Title>Maps for Disaster Preparedness</Card.Title>
                                <Card.Text>
                                    Disaster Preparedness and Maps for Local Self Government Bodies.
                                </Card.Text>
                            </Card.Body>
                        </span>
                    </Card>
                </Col>
            </Row>
            {/* <Row className="initiatives-section-row-3">    
                <Col md={4}>
                    <Card className="initiatives-section-card h-100">
                        <div class="middle">
                            <a href="https://orukkam.cfc.net.in" target="_blank">
                                <div class="text">
                                        View Details
                                </div>
                            </a>
                        </div>
                        <span class="card-content">
                            <img className="initiatives-section-card-img" src="/images/icon-4.png" alt="" />
                            <Card.Body>
                                <Card.Title>Munnar Disaster Management</Card.Title>
                                <Card.Text>
                                    Experience from Disaster preparedness and Early Warning.
                                </Card.Text>
                            </Card.Body>
                        </span>
                    </Card>
                </Col>
            </Row> */}
        </Container>
    );
}
export default InitiativesSection;