import {Navbar, Nav, Container} from 'react-bootstrap';

function NavSection() {
    return (
      <Navbar fixed="top" expand="lg" className="navbar">
        <Container fluid className="nav-bar-container">
          <Navbar.Brand href="#header">
            <img
              src="./images/logo.png"
              className="d-inline-block align-top"
              alt="data for change"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#initiatives">Initiatives</Nav.Link>
            <Nav.Link href="#stories">Stories</Nav.Link>
            <Nav.Link href="#technologies">Technologies</Nav.Link>
          </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
}
export default NavSection;    