import {Container, Row, Col} from 'react-bootstrap';

function TechnologiesSection() {
    return (
        <Container className="technologies-section" id="technologies">
            <Row>
                <Col xs={12}>
                <h2>Technologies</h2>
                </Col>
            </Row>
            <Row className="technologies-section-row-2">
                <Col xs={12}>
                    <div class="card-container">
                        <div class="float-layout">
                            <div class="technologies-section-card-img">
                                <img src="/images/tech-1.png"/>
                                <div class="technologies-section-card">
                                    <div class="technologies-section-card-title">
                                        <a href="/doc/documentaion-landslide-model.pdf" target="_blank">
                                        Preparing Landslide Susceptibility Maps
                                        </a></div>
                                    <div class="technologies-section-card-desc">
                                        Identifying areas which are subject to landslides and is measured from low to high.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            {/* <Row  className="mt-md-5">
                <Col xs={12} >
                    <div class="card-container">
                        <div class="float-layout">
                            <div class="technologies-section-card-img">
                                <img src="/images/tech-2.png"/>
                                <div class="technologies-section-card">
                                    <div class="technologies-section-card-title">Household Data Collection</div>
                                    <div class="technologies-section-card-desc">
                                        Gathering quality indicators, in a standardized way, which after analysis will provide the useful statistics.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row> */}
            <Row  className="mt-md-5">
                <Col xs={12}>
                    <div class="card-container">
                        <div class="float-layout">
                            <div class="technologies-section-card-img">
                                <img src="/images/tech-3.png"/>
                                <div class="technologies-section-card">
                                    <div class="technologies-section-card-title">
                                        <a href="https://opendata.dataforchange.in/2021/05/07/stream-mapping-using-srtm-datasets/" target="_blank">
                                        Mapping Streams in OSM
                                        </a>
                                    </div>
                                    <div class="technologies-section-card-desc">
                                        Adding spatial information of the streams to OpenStreetMap.org.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row  className="mt-md-5">
                <Col xs={12}> 
                    <div class="card-container">
                        <div class="float-layout">
                            <div class="technologies-section-card-img">
                                <img src="/images/tech-4.png"/>
                                <div class="technologies-section-card">
                                    <div class="technologies-section-card-title">
                                        <a href="https://opendata.dataforchange.in/2021/02/22/kannapuram-boundary-mapping/" target="_blank">LSG Ward Boundary Correction</a>
                                    </div>
                                    <div class="technologies-section-card-desc">
                                        Updating the spatial boundaries of each wards, Local self government bodies.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
export default TechnologiesSection;

