import React from "react";
import PropTypes from "prop-types";
import cfcSrc from "./cfc-logo.png";
import apcSrc from "./apc-logo.png";
import gpkSrc from "./gpk-logo.png";
import humeSrc from "./hume-logo.png";
import spaceSrc from "./space-logo.png";
import somaSrc from "./soma-logo.png";
import gpsSrc from "./gps1.png";

import "./home/css/Footer.css";

const ImageElement = (props) => (
  <span className="partners-logo">
    <a target="_blank"
      href={props.data.href}
      style={{ color: "#FFFFFF" }}>
      <img
        src={props.data.src}
        title={props.data.title || ""}
        alt={props.data.alt || ""}
        style={{ marginLeft: "10px" }}
      />
    </a>
  </span>
);

class Footer extends React.Component {
  static propTypes = {
    logo: PropTypes.object,
  };

  static defaultProps = {
    logo: {
      cfc: {
        src: cfcSrc,
        href: "https://cfc.net.in/",
        title: "CFC",
        alt: "CFC",
      },
      apc: {
        src: apcSrc,
        href: "https://www.apc.org/",
        title: "APC",
        alt: "APC",
      },
      space: {
        src: spaceSrc,
        href: "http://www.space-kerala.org/",
        title: "SPACE",
        alt: "SPACE",
      },
      gps: {
        src: gpsSrc,
        href: "http://gpsrenewables.org",
        title: "GPS",
        alt: "GPS",
      },
      soma: {
        src: somaSrc,
        href: "http://www.somaindia.org/",
        title: "SOMA",
        alt: "SOMA",
      },
      gpk: {
        src: gpkSrc,
        href: "http://grameena.org/",
        title: "GPK",
        alt: "GPK",
      },
      hume: {
        src: humeSrc,
        href: "http://www.humecentre.in/",
        title: "HUME",
        alt: "HUME",
      },
    },
  };

  render() {
    const { ...logo } = this.props.logo || {};
    const textColor = "#FFFFFF";
    const image = (
      <img
        src={logo.src}
        title={logo.title || ""}
        alt={logo.alt || ""}
        style={{ marginLeft: "10px" }}
      />
    );

    return (
      <div
        style={{
          backgroundColor: "#333333",
          minHeight: "90px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          marginTop: "3%",
        }}
      >
        <div className="container-fluid" style={{ minWidth: "100%" }}>
          <div className="container footer-section">
            <div className="row align-items-center">
              <div
                className="text-left col-sm-6 col-md-4 col-lg-4"
                style={{
                  color: textColor,
                  paddingLeft: "13px"
                }}
              >
                <div>
                  <span style={{ color: textColor }}>Powered By</span>
                  <ImageElement data={logo.gps} />
                </div>
              </div>
              {/* <div
                className="copy-right text-center col-sm-3 col-md-3 col-md-pull-4 col-lg-4 col-lg-pull-4"
                style={{ color: textColor }}
              ></div> */}
              <div
                className="address-div text-left col-sm-6 col-md-8 col-md-offset-1 col-md-push-4 col-lg-8 col-lg-offset-1 col-lg-push-4"
                style={{ color: textColor }}
              >
                <span style={{ color: textColor }}>Partners</span>
                <span>
                  <ImageElement data={logo.space} />
                  <ImageElement data={logo.gpk} />
                  <ImageElement data={logo.soma} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Footer;
