import {Container, Row, Col, Card, Button} from 'react-bootstrap';

function StoriesSection() {
    return (
        <Container className="stories-section" id="stories"> 
                <Row>
                    <Col xs={12}>
                    <h2>Stories</h2>
                    </Col>
                </Row>
                <Row className="stories-section-row-2">
                    <Col md={4}>
                    <Card className="stories-section-card">
                        <Card.Img class="card-img" src="/images/story-1.png" />
                        <Card.Body>
                            <Card.Title>Pettimudi case study</Card.Title>
                            <Card.Text>Heart breaking night</Card.Text>
                            <Card.Link href="https://dm.dataforchange.in/#/geostory/422" target="_blank">View Story</Card.Link>
                        </Card.Body>
                    </Card>
                    </Col>
                    <Col md={4}>
                    <Card className="stories-section-card">
                        <Card.Img class="card-img" src="/images/story-2.png" />
                        <Card.Body>
                            <Card.Title>Kavalappara case study</Card.Title>
                            <Card.Text>Battle for the lives</Card.Text>
                            <Card.Link href="https://dm.dataforchange.in/#/geostory/438" target="_blank">View Story</Card.Link>
                        </Card.Body>
                    </Card>
                    </Col>
                    <Col md={4}>
                    <Card className="stories-section-card">
                        <Card.Img class="card-img" src="/images/story-3.png" />
                        <Card.Body>
                            <Card.Title>Pozhuthana case study</Card.Title>
                            <Card.Text>Kurichyarmala Landslide</Card.Text>
                            <Card.Link href="https://dm.dataforchange.in/#/geostory/14" target="_blank">View Story</Card.Link>
                        </Card.Body>
                    </Card>
                    </Col>
                </Row>
            </Container>   
    );
}
export default StoriesSection;