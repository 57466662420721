import { 
  Button,
  Container,
  Row,
  Col, 
  Image,
  Card
} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './logo.svg';
import './App.css';
import StoriesSection from './home/StoriesSection';
import TechnologiesSection from './home/TechnologiesSection';
import InitiativesSection from './home/InitiativesSection';
import './home/css/InitiativesSection.css';
import './home/css/StoriesSection.css';
import './home/css/TechnologiesSection.css';


function App() {
  return (
    <div className="App" id="header">

        <Container fluid className="header-section">
          <Container>
          <Row>
            <Col xs={12}>
              <h1>Data For Change</h1>
              <p>A joint initiative of GPSRenewables, SPACE, SOMA, HUME and GPK</p>
            </Col>
          </Row>
          </Container>
        </Container>

        <StoriesSection />
        <InitiativesSection />
        <TechnologiesSection />
    </div>
  );
}

export default App;
